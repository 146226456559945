<template>
    <v-container>
        
        <v-data-table
			:loading="!isDownloaded"
            :headers="headers"
            :items="tasks"
            class="elevation-2"
            :sort-desc="false"
			:sort-by="['date']"
            :disable-pagination="true"
            :hide-default-footer="true"
            item-key="id"
        >
            <template v-slot:item.clientId="{ item }">
                {{ item.clientId ? item.clientId : ''}}
            </template>

            <template v-slot:item.actions="{ item }">
				<div class="d-flex align-center">
                    <RemoveTaskApprentice  small :id="item._id"/>
                    <EditTaskApprentice small :id="item._id"/>
				</div>
            </template>

        </v-data-table>

    </v-container>
</template>

<script>
const token = localStorage.getItem('user');

import io from 'socket.io-client';
import EditTaskApprentice from '@/components/molecules/EditTaskApprentice';
import RemoveTaskApprentice from '@/components/molecules/RemoveTaskApprentice';

export default {

    components: {
        EditTaskApprentice,
        RemoveTaskApprentice
    },
    data(){
        return{
			tasks: [],
			isDownloaded: false,
			clients: [],
			socket: io(this.url),
			text: 'Zadanie zostało usunięte pomyślnie',
            headers: [
                { text: 'Zadanie', align: 'left', sortable: true, value: 'title', },
                { text: 'Akcje', value: 'actions', sortable: false },
            ],
            modal: false,
        }
	},

	computed: {
		role(){
			return this.$store.getters.getUserRole
		},
	},
    methods:{
		getAllTasks(){
            this.$axios.get(`${this.url}api/apprentice-tasks`, { headers: { 'x-access-token': token } })
            .then(resp => {
				this.tasks = resp.data
				this.isDownloaded = true;
			})
		},
    },
    created(){
        this.getAllTasks()

        this.socket.on('newTaskAprrenticeAdded', () => {
            this.getAllTasks()
        });
        this.socket.on('newTaskAprrenticeEdited', () => {
            this.getAllTasks()
        });
        this.socket.on('taskAprrenticeDeleted', () => {
            this.getAllTasks()
        });
    }
}
</script>


<style scoped lang="scss">
	.opacity{
		opacity: .6 !important;
	}
</style>